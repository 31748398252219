import React from 'react';
import {NavLink as Link, Switch, Route} from 'react-router-dom';

// import child components
import {Counter} from '../counter';
import {Post} from '../post';
import Workers from "../workers/allcategories.component";
import Master from "../master/master.component";
import SubCategory from "../subcategory/subcategory.component";
import MainCategory from "../maincategory/maincategory.component";
import Home from "../home/home.component";
import Information from "../information/information.component";
import Vacancy from "../vacancy/vacancy.component";
import Vacancyone from "../vacancyone/vacancyone.component";

// export entry application component
export class App extends React.Component {
    constructor() {
        console.log('App.constructor()');
        super();
    }

    // render view
    render() {
        console.log('App.render()');

        return (<>
                <div>
                    <div id="wrapper">

                        <header className="header__fixed">
                            <div className="container">
                                <div className="wrap d-flex">
                                    <Link to={"/"} className="logo" title="РемонтВсего">Ремонт<span
                                        className="text__color-green">Всего</span></Link>
                                    <a href="#" className="city__change-button show__popup"
                                       rel="popup__city">Санкт-Петербург</a>

                                    <ul className="main__menu d-flex">
                                        <li>
                                            <Link to="/task">Все услуги</Link>
                                        </li>
                                        <li>
                                            <Link to="/vacancy">Вакансии</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </header>

                        <Switch>
                            <Route exact path="/" render={({staticContext, ...props}) => <Home {...props} />}/>
                            <Route path="/master/:pid"
                                   render={({staticContext, ...props}) => <Master {...props} />}/>
                            <Route path="/task/:id/:sid"
                                   render={({staticContext, ...props}) => <SubCategory {...props} />}/>
                            <Route path="/task/:id"
                                   render={({staticContext, ...props}) => <MainCategory {...props} />}/>
                            <Route path="/task" component={Workers}/>
                            <Route path="/information/:alias" render={({staticContext, ...props}) => <Information {...props} />}/>
                            <Route path="/vacancy/:id" render={({staticContext, ...props}) => <Vacancyone {...props} />}/>
                            <Route path="/vacancy" render={({staticContext, ...props}) => <Vacancy {...props} />}/>
                        </Switch>

                        <footer>
                            <div className="wrap">
                                <div className="container">
                                    <a href="/" className="logo" title="РемонтВсего">Ремонт<span
                                        className="text__color-grey">Всего</span></a>
                                    <div className="wrap d-flex item-grid item-grid-3 footer__info">
                                        <div className="item">
                                            <div className="wrap">
                                                <div className="wrap footer__contact">
                                                    <p className="text__small">
                                                        <span
                                                            className="text__color-white">Контактная информация:</span>
                                                    </p>
                                                    <p className="text__small">
                                                        Бесплатный звонок по РФ:<br/>
                                                        <span
                                                            className="icon__before icon__phone">8 800 001-23-45</span>
                                                    </p>
                                                    <p className="text__small">
                                                        E-mail по общим вопросам:<br/>
                                                        <span className="icon__before icon__mail">hello@gotovo.ru</span>
                                                    </p>
                                                    <p className="text__small">
                                                        Время работы тех поддержки:<br/>
                                                        <span className="icon__before icon__clock">8:00 - 22:00 (без выходных)</span>
                                                    </p>
                                                    <p className="text__small">
                                                        Сообщество:<br/>
                                                        <span className="icon__before icon__link">Telegram канал</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="wrap">
                                                <div className="wrap footer__legal">
                                                    <p className="text__small">
                                                        <span
                                                            className="text__color-white">Реквизиты «РемонтВсего»:</span>
                                                    </p>
                                                    <p className="text__small">ООО "Онлайн-сервис РемонтВсего"</p>
                                                    <p className="text__small">
                                                        ИНН 7801221445<br/>
                                                        КПП 780501001<br/>
                                                        ОГРН 1027800524465
                                                    </p>
                                                    <p className="text__small">Юридический адрес: 198096, город
                                                        Санкт-Петербург, проспект Стачек, дом 67</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="wrap">
                                                <ul className="wrap footer__menu d-flex text__small">
                                                    <li><Link to="/information/about">О проекте</Link>
                                                        <ul>
                                                            <li>
                                                                <Link to="/information/prices">Условия и цены</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link to="/task">Все услуги</Link>
                                                        <ul>
                                                            <li>
                                                                <Link to="/task/1">Ремонт помещений</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/task/24">Ремонт транспорта</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/task/69">Ремонт техники</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap">
                                <div className="container">
                                    <div className="wrap d-flex text__small text__color-white">
                                        © 2022. «РемонтВсего» - Сервис по подбору надежных исполнителей для выполнения
                                        заданий любой сложности.
                                    </div>
                                    <ul className="wrap d-flex footer__menu text__small">
                                        <li>
                                            <Link to="/information/offer">Публичная оферта</Link>
                                        </li>
                                        <li>
                                            <Link to="/information/terms_of_use">Пользовательское соглашение</Link>
                                        </li>
                                        <li>
                                            <Link to="/information/privacy">Политика конфиденциальности</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </>
        );
    }
}