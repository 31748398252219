import React from 'react';
import axios from 'axios';
import Config from '../../config';

import {NavLink as Link, Switch, Route, withRouter} from 'react-router-dom';


class Vacancy extends React.Component {
    state = {
        vacancy: [],
        isLoading: false,
        params: [],
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            Vacancy.fetchData(this.props.match.params).then(data => {
                this.setState({
                    vacancy: data.vacancy,
                    params: data.params
                });
            });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        Vacancy.fetchData(this.state.params).then(data => {
            console.log("mount")
            this.setState({
                vacancy: data.vacancy,
                params: data.params
            }, () => {

            });
        });
    }


    static fetchData(params) {
        return axios.get(Config.server + '/api/GetVacancy').then(response => {
            return {vacancy: response.data, params: params}
        })
    }


    render() {
        return (
            <div className="wrap" id="content_wrap">
                <div className="wrap content border-top">
                    <div className="container">
                        <h1>Вакансии</h1>

                        <div>
                            <div className="section-box">
                                <div className="basic-section basic-section--appearance-horizontal">
                                    <div className="vacancy-card">
                                        <div className="vacancy-card__inner">
                                            <div className="vacancy-card__date">
                                                <time dateTime="2024-04-08T11:01:04+03:00" className="basic-date">8 апреля</time>
                                            </div>
                                            <Link to={"/master/3"} className="vacancy-card__icon-link"></Link>
                                            <div className="vacancy-card__info">
                                                <div className="vacancy-card__company">
                                                    <div className="vacancy-card__company-title">
                                                        <Link to={"/master/3"} className="link-comp link-comp--appearance-dark">ЮТТА</Link>
                                                    </div>
                                                </div>
                                                <div className="vacancy-card__title">
                                                    <Link to={"/vacancy/1"} className="vacancy-card__title-link">Мастер отделочных работ</Link>
                                                </div>
                                                <div className="vacancy-card__meta">
                                                    <span>Полный рабочий день</span>
                                                </div>
                                                <div className="vacancy-card__salary">
                                                    <div className="basic-salary">от 50 000 до 120 000 ₽</div>
                                                </div>
                                                <div className="vacancy-card__skills">
                                                    <span>Нанесение штукатурки, шпаклевки</span><span className="inline-separator"> • </span>
                                                    <span>Малярные работы</span><span className="inline-separator"> • </span>
                                                    <span>Устройство стяжки, наливного пола</span><span className="inline-separator"> • </span>
                                                    <span>Укладка ламината, линолеума</span><span className="inline-separator"> • </span>
                                                    <span>Укладка плитки</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Vacancy)