import React from 'react';
import axios from 'axios';
import Config from '../../config';

import {NavLink as Link, Switch, Route, withRouter} from 'react-router-dom';

class AllCategories extends React.Component {
    state = {
        list: [],
        isLoading: false,
    }

    constructor(props) {
        super(props);

        // component state
        if (props.staticContext && typeof props.staticContext.list != "undefined") {
            this.state = {
                isLoading: false,
                list: props.staticContext.list,
            };
        } else if (window.initial_state && typeof window.initial_state.list != "undefined") {
            this.state = {
                isLoading: false,
                list: window.initial_state.list,
            };
            window.initial_state = {}
        } else {
            this.state = {
                isLoading: true,
                list: [],
            };
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        AllCategories.fetchData().then(data => {
            this.setState({
                list: data.list,
            });
        });
    }

    static fetchData() {
        return axios.get(Config.server + '/api/GetCategories').then(response => {
            return {list: response.data}
        })
    }

    render() {
        return (
            <div className="wrap" id="content_wrap">
                <div className="wrap content">
                    <div className="container">
                        <h1>Ремонт:</h1>
                        <div className="wrap d-flex item-grid item-grid-3to2 service-list__wrap">
                            {this.state.list.filter(x => x.parent_id == null).map(e => {
                                let linkto = e.q > 0 ? "/task/" + e.id : "/master/" + e.id
                                return <div className="item">

                                    <Link to={linkto} className="wrap service-list-wrap__item">
                                        <img src={"/assets/images/bg/service__list/" + e.id + ".jpg"}
                                             className="absolute-center"/>
                                        <div className="service-list-wrap__about">
                                            {e.name_rp}
                                        </div>
                                    </Link>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AllCategories)