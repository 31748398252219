import React from 'react';
import axios from 'axios';
import {NavLink as Link, Switch, Route, withRouter} from 'react-router-dom';
import Config from '../../config';
import './home.style.scss'


class Home extends React.Component {
    state = {
        listMain: [],
        isLoading: false,

        step: 0,

        selectedGroup: {},
        selectedItem: {},
        selectedUnit: {},
    }

    constructor(props) {
        super(props);

        // component state
        if (props.staticContext && props.staticContext.listMain != "undefined") {
            this.state = {
                isLoading: false,
                listMain: props.staticContext.listMain,
            };
        } else if (window.initial_state && typeof window.initial_state.listMain != "undefined") {
            this.state = {
                isLoading: false,
                listMain: window.initial_state.listMain,
            };
            window.initial_state = {}
        } else {
            this.state = {
                isLoading: true,
                listMain: [],
            };
        }
    }


    componentDidMount() {
        Home.fetchData().then(data => {
            this.setState({
                listMain: data.listMain,
            });
        });

        this.setState({step: 0})
    }

    static fetchData() {
        return axios.get(Config.server + '/api/GetCategories').then(response => {
            return {listMain: response.data}
        })
    }

    render() {
        console.log('Post.render()');

        return (
            <div className="wrap" id="content_wrap">

                <div className="wrap">
                    <div className="container">
                        <div className="wrap hello__block">
                            <h2 className="hello-block__title">
                                <span className="text__color-orange">Мы</span> в ответе за <span className="text__color-green">тех</span>, кого предлагаем</h2>

                            {this.state.step == 0 && <>
                            <div className="wrap master-selector">
                                <div
                                    className={"master-selector-button icon__before icon-pencil"}
                                    onClick={() => {
                                        this.setState({step: 1})
                                    }}
                                >Оставить заявку
                                </div>
                                <div
                                    className={"master-selector-button icon__before icon-magnifier"}
                                    onClick={() => {
                                        this.setState({step: 2})
                                    }}
                                >Выбрать
                                </div>
                            </div>
                            </>}

                            {this.state.step == 1 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Оставить заявку</strong></li>
                                    <li className={"active"}><i className="far fa-user"></i><strong>Исполнитель</strong></li>
                                    <li><i className="fas fa-wrench"></i><strong>Категория</strong></li>
                                    <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>
                            <div className={"master-wrapper-block"}>
                                <div className="wrap master-selector">
                                    <div
                                        className={"master-selector-button icon__before icon__person"}
                                        onClick={() => {
                                            this.setState({step: 11})
                                        }}
                                    >Частный мастер
                                    </div>
                                    <div
                                        className={"master-selector-button icon__before icon-briefcase"}
                                        onClick={() => {
                                            this.setState({step: 12})
                                        }}
                                    >Проверенные фирмы
                                    </div>
                                </div>
                            </div>
                            </>}

                            {this.state.step == 11 && <>
                            <ul className="master-progress">
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 0})
                                    }}
                                ><i className="fas fa-pencil-alt"></i><strong>Оставить заявку</strong></li>
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 1})
                                    }}
                                ><i className="far fa-user"></i><strong>Частный мастер</strong></li>
                                <li className={"active"}><i className="fas fa-wrench"></i><strong>Категория</strong></li>
                                <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                            </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == null).map((e, l) => {
                                        return <div
                                            className="item"
                                            onClick={() => {
                                                this.setState({selectedGroup: e, step: 111})
                                            }}
                                        >
                                            <div className="wrap">
                                                <div className="categories-list__main">
                                                    <span className="categories-list-main__title">{e.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 111 && <>
                            <ul className="master-progress">
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 0})
                                    }}
                                ><i className="fas fa-pencil-alt"></i><strong>Оставить заявку</strong></li>
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 1})
                                    }}
                                ><i className="far fa-user"></i><strong>Частный мастер</strong></li>
                                <li className={"active"}><i className="fas fa-wrench"></i><strong>{this.state.selectedGroup.name}</strong></li>
                                <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                            </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedGroup.id).map((e, l) => {
                                        return <div
                                            className="item"
                                            onClick={() => {
                                                this.setState({selectedItem: e, step: 1111})
                                            }}
                                        >
                                            <div className="wrap">
                                                <div className="categories-list__main">
                                                    <span className="categories-list-main__title">{e.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 1111 && <>
                            <ul className="master-progress">
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 0})
                                    }}
                                ><i className="fas fa-pencil-alt"></i><strong>Оставить заявку</strong></li>
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 1})
                                    }}
                                ><i className="far fa-user"></i><strong>Частный мастер</strong></li>
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 11})
                                    }}
                                ><i className="fas fa-wrench"></i><strong>{this.state.selectedItem.name}</strong></li>
                                <li className={"active"}><i className="fas fa-check"></i><strong>Готово</strong></li>
                            </ul>
                            <div className={"master-wrapper-block"}>
                                <div className={"step-master-form"}>
                                    <div className={"input"}>
                                        <input type={"text"} placeholder={"Телефон"}/>
                                    </div>
                                    <div className={"input"}>
                                        <input type={"text"} placeholder={"Имя"}/>
                                    </div>
                                    <div className={"text"}>
                                        <textarea placeholder={"Описание задачи"}></textarea>
                                    </div>
                                    <div>
                                        <button>Оставить заявку</button>
                                    </div>
                                </div>
                            </div>
                            </>}

                            {this.state.step == 12 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Оставить заявку</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 1})
                                        }}
                                    ><i className="far fa-user"></i><strong>Проверенные фирмы</strong></li>
                                    <li className={"active"}><i className="fas fa-wrench"></i><strong>Категория</strong></li>
                                    <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == null).map((e, l) => {
                                        return <div
                                            className="item"
                                            onClick={() => {
                                                this.setState({selectedGroup: e, step: 121})
                                            }}
                                        >
                                            <div className="wrap">
                                                <div className="categories-list__main">
                                                    <span className="categories-list-main__title">{e.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 121 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Оставить заявку</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 1})
                                        }}
                                    ><i className="far fa-user"></i><strong>Проверенные фирмы</strong></li>
                                    <li className={"active"}><i className="fas fa-wrench"></i><strong>{this.state.selectedGroup.name}</strong></li>
                                    <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedGroup.id).map((e, l) => {
                                        return <div
                                            className="item"
                                            onClick={() => {
                                                this.setState({selectedItem: e, step: 1221})
                                            }}
                                        >
                                            <div className="wrap">
                                                <div className="categories-list__main">
                                                    <span className="categories-list-main__title">{e.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 1221 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Оставить заявку</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 1})
                                        }}
                                    ><i className="far fa-user"></i><strong>Проверенные фирмы</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 12})
                                        }}
                                    ><i className="fas fa-wrench"></i><strong>{this.state.selectedItem.name}</strong></li>
                                    <li className={"active"}><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>
                            <div className={"master-wrapper-block"}>
                                <div className={"step-master-form"}>
                                    <div className={"input"}>
                                        <input type={"text"} placeholder={"Телефон"}/>
                                    </div>
                                    <div className={"input"}>
                                        <input type={"text"} placeholder={"Имя"}/>
                                    </div>
                                    <div className={"text"}>
                                        <textarea placeholder={"Описание задачи"}></textarea>
                                    </div>
                                    <div>
                                        <button>Оставить заявку</button>
                                    </div>
                                </div>
                            </div>
                            </>}

                            {this.state.step == 2 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Выбрать исполнителя</strong></li>
                                    <li className={"active"}><i className="far fa-user"></i><strong>Исполнитель</strong></li>
                                    <li><i className="fas fa-wrench"></i><strong>Категория</strong></li>
                                    <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>

                            <div className={"master-wrapper-block"}>
                                <div className="wrap master-selector">
                                    <div
                                        className={"master-selector-button icon__before icon__person"}
                                        onClick={() => {
                                            this.setState({step: 21})
                                        }}
                                    >Частный мастер
                                    </div>
                                    <div
                                        className={"master-selector-button icon__before icon-briefcase"}
                                        onClick={() => {
                                            this.setState({step: 22})
                                        }}
                                    >Проверенные фирмы
                                    </div>
                                </div>
                            </div>
                            </>}

                            {this.state.step == 21 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Выбрать исполнителя</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 2})
                                        }}
                                    ><i className="far fa-user"></i><strong>Частный мастер</strong></li>
                                    <li className={"active"}><i className="fas fa-wrench"></i><strong>Категория</strong></li>
                                    <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == null).map((e, l) => {
                                        return <div
                                            className="item"
                                            onClick={() => {
                                                this.setState({selectedGroup: e, step: 211})
                                            }}
                                        >
                                            <div className="wrap">
                                                <div className="categories-list__main">
                                                    <span className="categories-list-main__title">{e.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 211 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Выбрать исполнителя</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 2})
                                        }}
                                    ><i className="far fa-user"></i><strong>Проверенные фирмы</strong></li>
                                    <li className={"active"}><i className="fas fa-wrench"></i><strong>{this.state.selectedGroup.name}</strong></li>
                                    <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedGroup.id).length > 0 && <>
                                        {this.state.listMain.filter(x => x.parent_id == this.state.selectedGroup.id).map((e, l) => {
                                            return <div
                                                className="item"
                                                onClick={() => {
                                                    this.setState({selectedItem: e, step: 2211})
                                                }}
                                            >
                                                <div className="wrap">
                                                    <div className="categories-list__main">
                                                        <span className="categories-list-main__title">{e.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </>}
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedGroup.id).length == 0 && <div style={{
                                        textAlign: "center",
                                        marginTop: 20,
                                    }}>
                                        <Link to={"/master/" + this.state.selectedGroup.id} className="link__big-button">Выбрать</Link>
                                    </div>}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 2211 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Выбрать исполнителя</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 2})
                                        }}
                                    ><i className="far fa-user"></i><strong>Частный мастер</strong></li>
                                    <li className={"active"}><i className="fas fa-wrench"></i><strong>{this.state.selectedItem.name}</strong></li>
                                    <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedItem.id).length > 0 && <>
                                        {this.state.listMain.filter(x => x.parent_id == this.state.selectedItem.id).map((e, l) => {
                                            return <div
                                                className="item"
                                                onClick={() => {
                                                    this.setState({selectedUnit: e, step: 22221})
                                                }}
                                            >
                                                <div className="wrap">
                                                    <div className="categories-list__main">
                                                        <span className="categories-list-main__title">{e.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </>}
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedItem.id).length == 0 && <div style={{
                                        textAlign: "center",
                                        marginTop: 20,
                                    }}>
                                        <Link to={"/master/" + this.state.selectedItem.id} className="link__big-button">Выбрать</Link>
                                    </div>
                                    }
                                </div>
                            </div>
                            </>}

                            {this.state.step == 22221 && <>
                            <ul className="master-progress">
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 0})
                                    }}
                                ><i className="fas fa-pencil-alt"></i><strong>Выбрать исполнителя</strong></li>
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 2})
                                    }}
                                ><i className="far fa-user"></i><strong>Частный мастер</strong></li>
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 21})
                                    }}
                                ><i className="fas fa-wrench"></i><strong>{this.state.selectedUnit.name}</strong></li>
                                <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                            </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {<div style={{
                                        textAlign: "center",
                                        marginTop: 20,
                                    }}>
                                        <Link to={"/master/" + this.state.selectedUnit.id} className="link__big-button">Выбрать</Link>
                                    </div>}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 22 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Выбрать исполнителя</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 2})
                                        }}
                                    ><i className="far fa-user"></i><strong>Проверенные фирмы</strong></li>
                                    <li className={"active"}><i className="fas fa-wrench"></i><strong>Категория</strong></li>
                                    <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == null).map((e, l) => {
                                        return <div
                                            className="item"
                                            onClick={() => {
                                                this.setState({selectedGroup: e, step: 222})
                                            }}
                                        >
                                            <div className="wrap">
                                                <div className="categories-list__main">
                                                    <span className="categories-list-main__title">{e.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 222 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Выбрать исполнителя</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 2})
                                        }}
                                    ><i className="far fa-user"></i><strong>Проверенные фирмы</strong></li>
                                    <li className={"active"}><i className="fas fa-wrench"></i><strong>{this.state.selectedGroup.name}</strong></li>
                                    <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedGroup.id).length > 0 && <>
                                        {this.state.listMain.filter(x => x.parent_id == this.state.selectedGroup.id).map((e, l) => {
                                            return <div
                                                className="item"
                                                onClick={() => {
                                                    this.setState({selectedItem: e, step: 2222})
                                                }}
                                            >
                                                <div className="wrap">
                                                    <div className="categories-list__main">
                                                        <span className="categories-list-main__title">{e.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </>}
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedGroup.id).length == 0 && <div style={{
                                        textAlign: "center",
                                        marginTop: 20,
                                    }}>
                                        <Link to={"/master/" + this.state.selectedGroup.id} className="link__big-button">Выбрать</Link>
                                    </div>}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 2222 && <>
                            <ul className="master-progress">
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 0})
                                    }}
                                ><i className="fas fa-pencil-alt"></i><strong>Выбрать исполнителя</strong></li>
                                <li
                                    className={"active selected"}
                                    onClick={() => {
                                        this.setState({step: 2})
                                    }}
                                ><i className="far fa-user"></i><strong>Проверенные фирмы</strong></li>
                                <li className={"active"}><i className="fas fa-wrench"></i><strong>{this.state.selectedItem.name}</strong></li>
                                <li><i className="fas fa-check"></i><strong>Готово</strong></li>
                            </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedItem.id).length > 0 && <>
                                        {this.state.listMain.filter(x => x.parent_id == this.state.selectedItem.id).map((e, l) => {
                                            return <div
                                                className="item"
                                                onClick={() => {
                                                    this.setState({selectedUnit: e, step: 22222})
                                                }}
                                            >
                                                <div className="wrap">
                                                    <div className="categories-list__main">
                                                        <span className="categories-list-main__title">{e.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </>}
                                    {this.state.listMain.filter(x => x.parent_id == this.state.selectedItem.id).length == 0 && <div style={{
                                        textAlign: "center",
                                        marginTop: 20,
                                    }}>
                                        <Link to={"/master/" + this.state.selectedItem.id} className="link__big-button">Выбрать</Link>
                                    </div>}
                                </div>
                            </div>
                            </>}

                            {this.state.step == 22222 && <>
                                <ul className="master-progress">
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 0})
                                        }}
                                    ><i className="fas fa-pencil-alt"></i><strong>Выбрать исполнителя</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 2})
                                        }}
                                    ><i className="far fa-user"></i><strong>Проверенные фирмы</strong></li>
                                    <li
                                        className={"active selected"}
                                        onClick={() => {
                                            this.setState({step: 22})
                                        }}
                                    ><i className="fas fa-wrench"></i><strong>{this.state.selectedUnit.name}</strong></li>
                                    <li className={"active selected"}><i className="fas fa-check"></i><strong>Готово</strong></li>
                                </ul>

                            <div className={"master-wrapper-block"}>
                                <div className={""}>
                                    {<div style={{
                                        textAlign: "center",
                                        marginTop: 20,
                                    }}>
                                        <Link to={"/master/" + this.state.selectedUnit.id} className="link__big-button">Выбрать</Link>
                                    </div>}
                                </div>
                            </div>
                            </>}

                        </div>
                    </div>
                </div>

                <div className="wrap content service__list">
                    <div className="container">
                        <h2 className={"text__color-orange"}>Ремонт:</h2>
                        <div className="wrap d-flex item-grid item-grid-3to2 service-list__wrap">

                            <div className="item">
                                <Link to="/master/71" className="wrap service-list-wrap__item">
                                    <img src="/assets/images/bg/service__list/71.jpg"
                                         className="absolute-center"/>
                                    <div className="service-list-wrap__about">
                                        Телефонов
                                    </div>
                                </Link>
                            </div>

                            <div className="item">
                                <Link to="/task/2" className="wrap service-list-wrap__item">
                                    <img src="/assets/images/bg/service__list/2.jpg"
                                         className="absolute-center"/>
                                    <div className="service-list-wrap__about">
                                        Квартир
                                    </div>
                                </Link>
                            </div>

                            <div className="item">
                                <Link to="/master/100" className="wrap service-list-wrap__item">
                                    <img src="/assets/images/bg/service__list/100.jpg"
                                         className="absolute-center"/>
                                    <div className="service-list-wrap__about">
                                        Стиральных машин
                                    </div>
                                </Link>
                            </div>

                            <div className="item">
                                <Link to="/master/127" className="wrap service-list-wrap__item">
                                    <img src="/assets/images/bg/service__list/127.jpg"
                                         className="absolute-center"/>
                                    <div className="service-list-wrap__about">
                                        Холодильников
                                    </div>
                                </Link>
                            </div>

                            <div className="item">
                                <Link to="/task/24/25" className="wrap service-list-wrap__item">
                                    <img src="/assets/images/bg/service__list/25.jpg"
                                         className="absolute-center"/>
                                    <div className="service-list-wrap__about">
                                        Автомобилей
                                    </div>
                                </Link>
                            </div>

                            <div className="item">
                                <Link to="/task" className="wrap service-list-wrap__item">
                                    <img src="/assets/images/bg/service__list/0.jpg"
                                         className="absolute-center"/>
                                    <div className="service-list-wrap__about">
                                        Прочего
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="wrap content">
                    <div className="container">
                        <h2>Почему <span className="text__color-orange">у нас...</span></h2>
                        <div className="wrap d-flex item-grid item-grid-2 why-we__block">
                            <div className="item">
                                <div className="wrap">
                                    <h3>...стоит <span className="text__color-orange">сделать заказ?</span></h3>
                                    <p>У вас сломалась бытовая техника, разбился экран смартфона, затроил двигатель
                                        автомобиля или просто необходим косметический ремонт квартиры? Тогда портал
                                        «РемонтВсего» поможет в решении возникшей ситуации.</p>
                                    <p>На сайте огромное количество мастеров, бригад и компаний, но вам не придется
                                        обзванивать всех подряд в поисках нужного специалиста. Не регистрируясь,
                                        опишите, какие работы требуются, — умный поиск подберет для вас исполнителя,
                                        исходя из всех пожеланий.</p>
                                    <p>Все исполнители, представленные на сайте, проходят обязательную верификацию и
                                        сертификацию с нашей стороны для подтверждения своей квалификации.</p>
                                    <p>«РемонтВсего» - это специализированный сервис, где каждый посетитель сможет найти
                                        профессионала сферы ремонта или услуг!</p>
                                </div>
                            </div>
                            <div className="item item__img">
                                <div className="wrap">
                                    <img src="/assets/images/bg/service__list/service-list__mobile.jpg"
                                         className="absolute-center"/>
                                </div>
                            </div>
                        </div>
                        <div className="wrap d-flex item-grid item-grid-2 why-we__block">
                            <div className="item item__img">
                                <div className="wrap">
                                    <img src="/assets/images/bg/service__list/service-list__tool.jpg"
                                         className="absolute-center"/>
                                </div>
                            </div>
                            <div className="item">
                                <div className="wrap">
                                    <h3>...стоит <span className="text__color-orange">получить заказ?</span></h3>
                                    <p>Сервис «РемонтВсего» поможет исполнителю найти новых клиентов и зарабатывать на
                                        выполнении любых услуг.</p>
                                    <p>После прохождения простой процедуры верификации и подтверждения квалификации вам
                                        будет доступен постоянный поток заказов. Если в процессе общения с заказчиком по
                                        телефону или в переписке вы понимаете, что заказ вам не интересен, смело
                                        отказывайтесь — это никак не повлияет на дальнейшую работу с сервисом.</p>
                                    <p>Наш сервис поможет сэкономить деньги на рекламе, а для частного мастера ещё и на
                                        разработке собственного сайта.</p>
                                    <p>«РемонтВсего» - это гарантированные лиды для вашего бизнеса. Вам остается только
                                        конвертировать их в прибыль!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default withRouter(Home)