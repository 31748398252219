import React from 'react';
import axios from 'axios';
import haversine from "haversine"
import Config from '../../config';
import Modal from 'react-modal';
import * as ReactDOM from 'react-dom';


import {NavLink as Link, Switch, Route, withRouter} from 'react-router-dom';
import {Post} from "../post/post.component";
import {YMaps, Map, Clusterer, Placemark} from "react-yandex-maps";
import ActivePlacemark from "../active-placemark";

const customStyles = {
    overlay: {
        zIndex: 9999,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "none",
    },
};

class Master extends React.Component {
    state = {
        dataWork: [],
        isLoading: false,
        coords: [59.937818, 30.32266],
        params: [],
        zoom: 11,
        showModal: false,
        activeItem: {},
        showContacts: false,
        showMap: false,

        currentItem: {}
    }

    constructor(props) {
        super(props);

        // component state
        if (props.staticContext && typeof props.staticContext.dataWork != "undefined") {
            this.state = {
                isLoading: false,
                dataWork: props.staticContext.dataWork,
                coords: [59.937818, 30.32266],
                params: props.staticContext.params,
                zoom: 11,
            };
        } else if (window.initial_state && typeof window.initial_state.dataWork != "undefined") {
            this.state = {
                isLoading: false,
                dataWork: window.initial_state.dataWork,
                coords: [59.937818, 30.32266],
                params: window.initial_state.params,
                zoom: 11,
            };
            window.initial_state = {}
        } else {
            this.state = {
                isLoading: true,
                dataWork: [],
                coords: [59.937818, 30.32266],
                params: props.match.params,
                zoom: 11,
            };
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            Master.fetchData(this.props.match.params).then(data => {
                this.setState({
                    dataWork: data.dataWork,
                    params: data.params
                });
            });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        Master.fetchData(this.state.params).then(data => {
            console.log("mount")
            this.setState({
                dataWork: data.dataWork,
                params: data.params
            }, () => {
                /*if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        console.log(position.coords)
                        console.log(haversine({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            },
                            {
                                latitude: 59.937818,
                                longitude: 30.32266
                            }))

                        console.log(this.state)
                        if () < 30) {
                            this.setState({coords: [position.coords.latitude, position.coords.longitude]})
                        }
                    });
                }*/
            });
        });
    }

    choiceItem(item, index) {
        console.log(item)
        console.log(index)
        this.setState({
            currentItem: item,
            coords: [item.latitude, item.longitude],
            zoom: 19,
        });
    }

    static fetchData(params) {
        return axios.get(Config.server + '/api/GetWorkers?pid=' + params.pid).then(response => {
            return {dataWork: response.data, params: params}
        })
    }

    openModal(item) {
        this.setState({showModal: true, activeItem: item})
    }

    closeModal() {
        this.setState({showModal: false, activeItem: {}, showContacts: false})
    }

    render() {
        return (
            <div className="wrap" id="content_wrap">
                <Modal
                    isOpen={this.state.showModal}
                    onRequestClose={() => this.closeModal()}
                    style={customStyles}
                    contentLabel="Example Modal"
                    shouldCloseOnOverlayClick={true}
                >
                    <div className={"closeButton"} onClick={() => this.closeModal()}></div>
                    {this.state.showModal &&
                    <div>
                        <div className={"modalName"}>
                            {this.state.activeItem.name}
                        </div>
                        {this.state.activeItem.verified > 0 &&
                        <div className={"verified"}
                             style={{
                                 position: "relative",
                                 display: "inline-block",
                                 paddingLeft: 40,
                             }}> Проверено
                        </div>
                        }
                        {this.state.activeItem.verified > 0 && <div>
                            <div className={"send-variant"}>
                                <div className={"send-free"}>
                                    <span onClick={() => {
                                        this.setState({showContacts: true})
                                    }}>Напрямую</span>
                                </div>
                                <div className={"send-warranty"}><span>С гарантией</span></div>
                            </div>
                        </div>}
                        {this.state.showContacts && <div className={"send-contact"}>
                            {this.state.activeItem.phone}
                        </div>}
                    </div>
                    }
                </Modal>

                <div className="wrap content border-top">
                    <div className="container">
                        {typeof this.state.dataWork != "undefined" && typeof this.state.dataWork[0] != "undefined" && <>
                            <h1> {!this.state.dataWork[0].name_rp ? "" : "Ремонт"}
                                <span
                                    className="text__color-orange">{!this.state.dataWork[0].name_rp ? this.state.dataWork[0].name : this.state.dataWork[0].name_rp}</span>
                            </h1>
                            <p className="text__color-grey"></p>

                            <div className={"mapSwitcher"}>
                                <div className={this.state.showMap ? "hideList" : "showList"} onClick={() => {
                                    this.setState({showMap: true})
                                }}><span>Карта</span></div>
                                <div className={this.state.showMap ? "showMap" : "hideMap"} onClick={() => {
                                    this.setState({showMap: false})
                                }}><span>Список</span></div>
                            </div>

                            <div className="wrap d-flex item-grid item-grid-2 result__list">
                                <div className={this.state.showMap ? "item hideList" : "item showList"} style={{paddingRight: 20}}>
                                    <div className="wrap">
                                        <ul className="wrap result-list__wrap">
                                            {this.state.dataWork.length > 2 && this.state.dataWork[1].length > 0 && <>
                                                {this.state.dataWork[1].map((item, index) =>
                                                    <li className="wrap">
                                                        {item.verified > 0 && <div className={"verified"}></div>}

                                                        <div className="wrap d-flex result__company">
                                                            <div className="result-company__logo">
                                                            </div>
                                                            <div className="result-company__about d-flex fd-column">
                                                                <div
                                                                    className="wrap result-company__title">{item.name}</div>
                                                                <div className="wrap result-company__info">
                                                                    <div
                                                                        className="result-company-info__address icon__before icon__address">
                                                                        <p className="text__small">{item.name_sub}</p>
                                                                    </div>

                                                                    {item.phone &&
                                                                    <div
                                                                        className="result-company-info__phone icon__before icon__phone">
                                                                        <p className="text__small">
                                                                        <span
                                                                            className="result-company-phone__showButton">
                                                                            <span
                                                                                onClick={() => this.openModal(item)}>Показать телефон</span>
                                                                        </span>
                                                                            <span
                                                                                className="result-company-phone__hideNum">
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                    }
                                                                    {item.email &&
                                                                    <div
                                                                        className="result-company-info__site icon__before icon__link">
                                                                        <p className="text__small">{item.email}</p>
                                                                    </div>
                                                                    }

                                                                    <div className="addresses">
                                                                        {this.state.dataWork.length > 2 && this.state.dataWork[2].length > 0 && <>
                                                                            {this.state.dataWork[2].filter(x => x.oprganisation_id == item.id).map((item2, index2) =>
                                                                                <div
                                                                                    id={"adr-" + item2.id}
                                                                                    className={"addresses-item " + ((typeof this.state.currentItem != "undefined" && typeof this.state.currentItem.id != "undefined" && item2.id == this.state.currentItem.id) ? "active" : "")}
                                                                                    onClick={() => this.choiceItem(item2, index2)}
                                                                                >
                                                                                    <div
                                                                                        className="result-company-info__site icon__before icon__adress">
                                                                                        <p className="text__extra_extra-small">{item2.latitude}, {item2.longitude}</p>
                                                                                        <p className="text__small">{item2.address}</p>
                                                                                    </div>

                                                                                    {item2.phone && (typeof this.state.currentItem != "undefined" && typeof this.state.currentItem.id != "undefined" && item2.id == this.state.currentItem.id) &&
                                                                                    <div
                                                                                        className="result-company-info__phone icon__before icon__phone">
                                                                                        <p className="text__small">
                                                                                                <span className="result-company-phone__showButton">
                                                                                                    <span
                                                                                                        onClick={() => this.openModal(item2)}>Показать телефон</span>
                                                                                                    </span>
                                                                                            <span
                                                                                                className="result-company-phone__hideNum">
                                                                                                </span>
                                                                                        </p>
                                                                                    </div>
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                        </>}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className={this.state.showMap ? "item showMap" : "item hideMap"}>
                                    <div className="wrap result-list__map">

                                        <YMaps>
                                            <Map defaultState={{center: this.state.coords, zoom: this.state.zoom}}
                                                 state={{center: this.state.coords, zoom: this.state.zoom}}
                                                 width={"100%"}
                                                 height={"100%"}>
                                                <Clusterer options={{
                                                    preset: 'islands#invertedVioletClusterIcons',
                                                    groupByCoordinates: false
                                                }}>
                                                    {this.state.dataWork.length > 2 && this.state.dataWork[2].length > 0 && <>
                                                        {this.state.dataWork[2].map((item, index) => <>
                                                                <Placemark
                                                                    instanceRef={ref => {
                                                                        ref && ref.events.add('balloonopen', (e) => {
                                                                            let els = document.querySelectorAll(".addresses-item")

                                                                            els.forEach(e => e.classList.remove("active"))

                                                                            let el = document.querySelector("#adr-" + e.originalEvent.currentTarget.properties._data.id)
                                                                            el.scrollIntoView({behavior: "smooth", block: "center", inline: "start"})
                                                                            el.classList.add("active")
                                                                        });
                                                                    }}

                                                                    key={index}
                                                                    geometry={[item.latitude, item.longitude]}
                                                                    modules={
                                                                        ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                                                    }
                                                                    properties={{
                                                                        id: item.id,
                                                                        hintContent: item.name,
                                                                        balloonContent: '<div>' + '<b style="font-weight: bold">' + item.name + '</b></div>' +
                                                                            '<div>' + item.name_sub + '</div>' +
                                                                            '<div>' + item.address + '</div>' +
                                                                            '<div>' + item.phone + '</div>'
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </>}
                                                </Clusterer>
                                            </Map>
                                        </YMaps>

                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Master)