import React from 'react';
import axios from 'axios';
import Config from '../../config';

import {NavLink as Link, Switch, Route, withRouter} from 'react-router-dom';


class Vacancyone extends React.Component {
    state = {
        vacancy: [],
        isLoading: false,
        params: [],
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            Vacancyone.fetchData(this.props.match.params).then(data => {
                this.setState({
                    vacancy: data.vacancy,
                    params: data.params
                });
            });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        Vacancyone.fetchData(this.state.params).then(data => {
            console.log("mount")
            this.setState({
                vacancy: data.vacancy,
                params: data.params
            }, () => {

            });
        });
    }


    static fetchData(params) {
        return axios.get(Config.server + '/api/GetVacancy').then(response => {
            return {vacancy: response.data, params: params}
        })
    }


    render() {
        return (
            <div className="wrap" id="content_wrap">
                <div className="wrap content border-top">
                    <div className="container">
                        <div>
                            <article className="vacancy-show">
                                <div className="page-section">
                                    <div className="section-box">
                                        <div className="basic-section">
                                            <div className="vacancy-header__title">
                                                <div className="page-title page-title--justifyContent-space-between">
                                                    <h1 className="page-title__title">Мастер отделочных работ</h1>
                                                </div>
                                                <div className="vacancy-header__date">
                                                    <span className="basic-text basic-text--color-muted">
                                                    <time
                                                        dateTime="2024-04-01T11:03:27+03:00" className="basic-date">1 апреля</time>
                                                </span>
                                                </div>
                                            </div>
                                            <div className="content-section">
                                                <div className="content-section__header">
                                                    <h2 className="content-section__title"> Зарплата </h2>
                                                </div>
                                                <div className="basic-salary basic-salary--appearance-vacancy-header">от 50 000 до 120 000 ₽</div>
                                            </div>
                                            <div className="content-section">
                                                <div className="content-section__header">
                                                    <h2 className="content-section__title"> Требования </h2>
                                                </div>
                                                <div className="vacancy-card__skills">
                                                    <span>Нанесение штукатурки, шпаклевки</span><span className="inline-separator"> • </span>
                                                    <span>Малярные работы</span><span className="inline-separator"> • </span>
                                                    <span>Устройство стяжки, наливного пола</span><span className="inline-separator"> • </span>
                                                    <span>Укладка ламината, линолеума</span><span className="inline-separator"> • </span>
                                                    <span>Укладка плитки</span>
                                                </div>
                                            </div>
                                            <div className="content-section">
                                                <div className="content-section__header">
                                                    <h2 className="content-section__title"> Местоположение и тип занятости </h2>
                                                </div>
                                                <span className="inline-list">
                                                    <span>
                                                        <span>Полный рабочий день</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="page-section">
                                    <div className="section-box">
                                        <div className="basic-section basic-section--appearance-vacancy-description">
                                            <h2 className="section-title section-title--divider">
                                                <span
                                                    className="section-title__title">Описание вакансии</span>
                                                <span className="section-title__right">

                                                </span>
                                            </h2>


                                            <div className="vacancy-description__text">
                                                <h3>Мы ждем, что у тебя:</h3>
                                                <p>
                                                    <ul className="vacancy__details-list">
                                                        <li className="vacancy__details-item">Опыт работы не менее 3-х лет;</li>
                                                        <li className="vacancy__details-item">Наличие профессионального инструмента;</li>
                                                        <li className="vacancy__details-item">Знание технологии применения строительных материалов;</li>
                                                        <li className="vacancy__details-item">Ответственность и исполнительность;</li>
                                                        <li className="vacancy__details-item">Наличие документов для осуществления трудовой деятельности на территории РФ
                                                            обязательно.
                                                        </li>
                                                    </ul>
                                                </p>
                                                <h3>Обязанности:</h3>

                                                <p>
                                                    <ul className="vacancy__details-list">
                                                        <li className="vacancy__details-item">Нанесение штукатурки, шпаклевки;</li>
                                                        <li className="vacancy__details-item">Малярные работы;</li>
                                                        <li className="vacancy__details-item">Устройство стяжки, наливного пола;</li>
                                                        <li className="vacancy__details-item">Укладка ламината, линолеума;</li>
                                                        <li className="vacancy__details-item">Укладка плитки.</li>
                                                    </ul>
                                                </p>
                                                <h3>Условия:</h3>
                                                <p>
                                                    <ul className="vacancy__details-list">
                                                        <li className="vacancy__details-item">График работы: 5/2 c 9:00 до 19:00 (обсуждаемо);</li>
                                                        <li className="vacancy__details-item">Сдельная заработная плата каждые 7-10 дней;</li>
                                                        <li className="vacancy__details-item">Иногородним предоставляется общежитие;</li>
                                                        <li className="vacancy__details-item">Выдача спецодежды;</li>
                                                        <li className="vacancy__details-item">Оплата проезда за МКАД;</li>
                                                        <li className="vacancy__details-item">Постоянный объем отделочных работ;</li>
                                                        <li className="vacancy__details-item">Объекты в районе проживания.</li>
                                                    </ul>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Vacancyone)