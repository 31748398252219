import React from 'react';
import axios from 'axios';
import Config from '../../config';

import {NavLink as Link, Switch, Route, withRouter} from 'react-router-dom';

class MainCategory extends React.Component {
    state = {
        dataCat: [],
        isLoading: false,
        params: [],
    }

    constructor(props) {
        super(props);
        // component state
        if (props.staticContext && typeof props.staticContext.dataCat != "undefined") {
            this.state = {
                isLoading: false,
                dataCat: props.staticContext.dataCat,
                params: props.staticContext.params,
            };
        } else if (window.initial_state && typeof window.initial_state.dataCat != "undefined") {
            this.state = {
                isLoading: false,
                dataCat: window.initial_state.dataCat,
                params: window.initial_state.params,
            };
            window.initial_state = {}
        } else {
            this.state = {
                isLoading: true,
                dataCat: [],
                params: props.match.params,
            };
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            MainCategory.fetchData(this.props.match.params).then(data => {
                this.setState({
                    dataCat: data.dataCat,
                    params: data.params
                });
            });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        console.log("mount Maincat")
        MainCategory.fetchData(this.state.params).then(data => {
            this.setState({
                dataCat: data.dataCat,
                params: data.params
            });
        });
    }

    static fetchData(params) {
        console.log(params)
        return axios.get(Config.server + '/api/GetCategory?id=' + params.id).then(response => {
            return {dataCat: response.data, params: params}
        })
    }

    render() {
        return (
            <div className="wrap" id="content_wrap">
                <div className="wrap content border-top">
                    <div className="container">
                        {typeof this.state.dataCat != "undefined" && typeof this.state.dataCat[0] != "undefined" && <>
                            <h1>Ремонт <span
                                className="text__color-orange">{this.state.dataCat[0].name_rp != "" ? this.state.dataCat[0].name_rp : this.state.dataCat[0].name}</span>
                            </h1>

                            <div className="wrap d-flex item-grid item-grid-3to2 service-list__wrap">
                                {this.state.dataCat[1].filter(x => x.parent_id == this.state.dataCat[0].id).map(e => {
                                    let linkto = e.q > 0 ? "/task/" + this.state.dataCat[0].id + "/" + e.id : "/master/" + e.id
                                    return <div className="item">

                                        <Link to={linkto} className="wrap service-list-wrap__item">
                                            <img src={"/assets/images/bg/service__list/" + e.id + ".jpg"}
                                                 className="absolute-center"/>
                                            <div className="service-list-wrap__about">
                                                {e.name}
                                            </div>
                                        </Link>
                                    </div>
                                })}
                            </div>


                        </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MainCategory)