import React from 'react';
import axios from 'axios';
import {NavLink as Link, Switch, Route, withRouter} from 'react-router-dom';
import Config from '../../config';


class Information extends React.Component {
    state = {
        dataPage: [],
        isLoading: false,
        params: [],
    }

    constructor(props) {
        super(props);

        // component state
        if (props.staticContext && props.staticContext.dataPage != "undefined") {
            this.state = {
                isLoading: false,
                dataPage: props.staticContext.dataPage,
                params: props.staticContext.params,
            };
        } else if (window.initial_state && typeof window.initial_state.dataPage != "undefined") {
            this.state = {
                isLoading: false,
                dataPage: window.initial_state.dataPage,
                params: window.initial_state.params,
            };
            window.initial_state = {}
        } else {
            this.state = {
                isLoading: true,
                dataPage: [],
                params: props.match.params,
            };
        }

        console.log("page constructor")
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            Information.fetchData(this.props.match.params).then(data => {
                this.setState({
                    dataPage: data.dataPage,
                    params: data.params
                });
            });
        }
    }


    componentDidMount() {
        console.log("page mount")
        window.scrollTo(0, 0)
        Information.fetchData(this.state.params).then(data => {
            this.setState({
                dataPage: data.dataPage,
                params: data.params
            });
        });
    }

    static fetchData(params) {
        return axios.get(Config.server + '/api/GetPage?alias=' + params.alias).then(response => {
            return {
                dataPage: response.data,
                params: params
            }
        })
    }

    render() {
        return (
            <div className="wrap" id="content_wrap">
                <div className="wrap" id="content_wrap">
                    <div className="wrap content">
                        <div className="container">
                            {typeof this.state.dataPage != "undefined" && typeof this.state.dataPage[0] != "undefined" && <>
                                <h1>{this.state.dataPage[0].name}</h1>
                                <p>{this.state.dataPage[0].content}</p>
                            </>}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(Information)